import { getDefaultConfig } from '@rainbow-me/rainbowkit'

import {
  rainbowWallet,
  metaMaskWallet,
  coinbaseWallet,
  walletConnectWallet,
  ledgerWallet,
  okxWallet,
  mewWallet,
  trustWallet,
  uniswapWallet,
  bitgetWallet,
  bitskiWallet,
  coin98Wallet,
  zerionWallet,
  argentWallet,
  coreWallet,
  omniWallet,
  phantomWallet,
  safepalWallet,
  clvWallet,
  foxWallet,
  subWallet,
  dawnWallet,
  gateWallet,
  safeheronWallet,
  oktoWallet,
  safeWallet,
  tahoWallet,
  zealWallet,
  bloomWallet,
  braveWallet,
  bybitWallet,
  desigWallet,
  frameWallet,
  kaikasWallet,
  bifrostWallet,
  bitverseWallet,
  kresusWallet,
  rabbyWallet,
  roninWallet,
  xdefiWallet,
  oneKeyWallet,
  ramperWallet,
  enkryptWallet,
  imTokenWallet,
  oneInchWallet,
  injectedWallet,
  frontierWallet,
  talismanWallet,
  tokenaryWallet,
  tokenPocketWallet,
} from '@rainbow-me/rainbowkit/wallets'
import { mainnet } from 'viem/chains'

export const config = getDefaultConfig({
  appName: 'MOOV',
  projectId: 'd5894154dfe1a977bcb950f5b9dbe511',
  chains: [mainnet],
  wallets: [
    {
      groupName: 'Recommended',
      wallets: [metaMaskWallet, walletConnectWallet, injectedWallet, mewWallet, ledgerWallet, uniswapWallet],
    },
    {
      groupName: 'Exchanges',
      wallets: [coinbaseWallet, okxWallet, bitgetWallet, coin98Wallet, gateWallet, bybitWallet],
    },
    {
      groupName: 'Others',
      wallets: [
        safepalWallet,
        trustWallet,
        argentWallet,
        rainbowWallet,
        oneInchWallet,
        zerionWallet,
        phantomWallet,
        frameWallet,
        safeWallet,
        bitskiWallet,
        coreWallet,
        omniWallet,
        clvWallet,
        foxWallet,
        subWallet,
        dawnWallet,
        gateWallet,
        safeheronWallet,
        oktoWallet,
        tahoWallet,
        zealWallet,
        bloomWallet,
        braveWallet,
        desigWallet,
        kaikasWallet,
        bifrostWallet,
        bitverseWallet,
        kresusWallet,
        rabbyWallet,
        roninWallet,
        xdefiWallet,
        oneKeyWallet,
        ramperWallet,
        enkryptWallet,
        imTokenWallet,
        frontierWallet,
        talismanWallet,
        tokenaryWallet,
        tokenPocketWallet,
      ],
    },
  ],
})

export default config
