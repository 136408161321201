import { extendTheme } from '@chakra-ui/react'

const myTheme = {
  fonts: {
    heading: `Poppins, monospace`,
    body: `Poppins, monospace`,
  },
  config: {
    initialColorMode: 'dark',
    useSystemColorMode: false,
  },

  components: {
    Button: {
      baseStyle: {
        borderRadius: 'full',
        size: 'lg',
      },
      defaultProps: {
        variant: 'primary',
      },
      variants: {
        primary: {
          px: '12',
          bg: 'brand.500',
          _hover: {
            bg: 'brand.400',
          },
          color: 'white',
          _disabled: {
            bg: 'brand.700',
            color: 'whiteAlpha.700',
            opacity: '1',
            _hover: {
              bg: 'brand.700',
            },
          },
        },
        secondary: {
          px: '12',
          border: '1px solid',
          borderColor: 'brand.500',
          _hover: {},
          color: 'white',
        },
      },
    },
    Menu: {
      baseStyle: {
        list: {
          p: 1,
          bg: 'gray.200',
          borderRadius: '12px',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        },
        item: {
          bg: 'gray.200',
          transition: 'all 0.2s ease-in-out',
          _focus: {
            bg: 'whiteAlpha.100',
          },
          _hover: {
            bg: 'brand.500',
            color: 'white',
            textDecoration: 'none',
            fontWeight: 'normal',
          },
          fontWeight: 'normal',
          fontSize: 'sm',
          borderRadius: '6px',
        },
      },
    },
    Tabs: {
      defaultProps: {
        variant: 'primary',
        bg: 'red',
      },
      baseStyle: {
        tab: {
          borderRadius: 'full',
          transition: 'all 0.2s ease-in-out',
          _hover: {
            bg: 'whiteAlpha.300',
          },
        },
        tablist: {
          borderRadius: '8px',
          p: '0',
          mb: '4',

          display: 'inline-flex',
        },
        tabpanel: {
          p: '0',
        },
      },
      variants: {
        primary: {
          tab: {
            '&&': {
              position: 'relative',
              fontSize: { base: 'sm', md: 'sm' },
              fontWeight: 'medium',
              color: 'white',
              borderRadius: 'full',

              _selected: {
                bg: 'white',
                color: 'gray.900',
              },
              _hover: {},
              _focus: { boxShadow: 'none' },
              width: '100%',
              mx: '1',
              py: '2',
              letterSpacing: '0.3px',
              transition: 'all 0.14s ease-in-out 0s',
              ':first-of-type': {
                ml: '0',
              },
              ':last-of-type': {
                mr: '1',
              },
            },

            tabpanels: {
              p: '0',
            },
            tabpanel: {
              w: '100%',
              h: '100%',
              p: '0',
            },
          },
        },
      },
    },
  },
  colors: {
    gray: {
      50: '#f9f9f9',
    },
    brand: {
      900: '#000933',
      800: '#001166',
      700: '#001a99',
      600: '#0022cc',
      500: '#002bff',
      400: '#3355ff',
      300: '#6680ff',
      200: '#99aaff',
      100: '#ccd5ff',
      50: '#e6eaff',
    },
  },
  styles: {
    global: {
      'html, body': {
        margin: 0,
        padding: 0,
        bg: '#000000',
        color: '#fafafa',
      },
    },
  },
}

export default extendTheme(myTheme)
