import { Center, Container, Tab, TabList, TabPanel, TabPanels, Tabs, VStack } from '@chakra-ui/react'
import StakingTier2 from './StakingTier2'
import StakingTier3 from './StakingTier3'
import { useAccount } from 'wagmi'
import { useCallback, useEffect, useMemo, useState } from 'react'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'

export default function StakingPage() {
  const { address } = useAccount()
  const [isActivated, setIsActivated] = useState(false)

  const { tier } = useParams()

  const navigate = useNavigate()

  const currentTab = useMemo(() => {
    switch (tier) {
      case 'tier2':
        return 0
      case 'tier3':
        return 1
      default:
        return 0
    }
  }, [tier])

  useEffect(() => {
    if (tier !== 'tier2' && tier !== 'tier3') {
      navigate('/staking/tier2')
    }
  }, [tier, navigate])

  const fetchAddressActivated = useCallback(async () => {
    try {
      const res = await axios.get(`/staking/${address}/activated`)

      setIsActivated(res.data.activated)
    } catch (err) {
      setIsActivated(false)
    }
  }, [address])

  useEffect(() => {
    fetchAddressActivated()
  }, [fetchAddressActivated])

  return (
    <VStack w="100%" align="start">
      <Container pt="0" pb="20" maxW="700px" px="20px">
        <VStack w="100%">
          <Tabs w="100%" index={currentTab}>
            <Center>
              <TabList w="100%">
                <Tab onClick={() => navigate('/staking/tier2')}>Tier 2</Tab>
                <Tab onClick={() => navigate('/staking/tier3')}>Tier 3</Tab>
              </TabList>
            </Center>
            <TabPanels>
              <TabPanel>
                <StakingTier2 isActivated={isActivated} refetchActivation={fetchAddressActivated} />
              </TabPanel>
              <TabPanel>
                <StakingTier3 isActivated={isActivated} refetchActivation={fetchAddressActivated} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </VStack>
      </Container>
    </VStack>
  )
}
