import claimContractAbi from '../abis/claim-abi.json'
import stakingContractAbi from '../abis/staking-abi.json'
import tokenContractAbi from '../abis/token-abi.json'

export const claimAbi = claimContractAbi
export const stakingAbi = stakingContractAbi
export const tokenAbi = tokenContractAbi

// type `0x${string}`

// TESTNET:
/*
export const addresses: Record<'claim' | 'token' | 'staking', `0x${string}`> = {
  claim: '0xA04cD5153d2f81aBD32d218A9644a2b5c264f60B',
  token: '0xE3d0D3Aa2556B77c233BF10259142E23c3C3AFdB', //'0x4116f14b6d462b32a1c10f98049e4b1765e34fa9', MAINNET
  staking: '0x095dcE30672163a57Babb2bb880707a7a1F8850d', //'0xD57412a2a973AC62feEC2090D291F7FA4f4D2180' MAINNET
}
*/

// MAINNET:
export const addresses: Record<'claim' | 'token' | 'staking', `0x${string}`> = {
  claim: '0xA04cD5153d2f81aBD32d218A9644a2b5c264f60B',
  token: '0x4116f14b6d462b32a1c10f98049e4b1765e34fa9',
  staking: '0xD57412a2a973AC62feEC2090D291F7FA4f4D2180',
}
