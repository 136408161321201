import { Center, HStack, Text, Image, Button, VStack, Stack, Link as CLink } from '@chakra-ui/react'
import { ConnectButton, useConnectModal } from '@rainbow-me/rainbowkit'
import { useAccount } from 'wagmi'
import MoovLogo from '../assets/moovlogo.svg'

export default function Header() {
  const { address } = useAccount()
  const { openConnectModal } = useConnectModal()

  return (
    <VStack w="100%" spacing="0" zIndex="2">
      <HStack
        display={{ base: 'none', md: 'flex' }}
        fontWeight="semibold"
        bg="brand.500"
        w="100%"
        justify="center"
        spacing="1"
        p="2"
        cursor="default"
      >
        <HStack spacing="1.5">
          <Center fontSize="10px" h="5" w="10" bg="white" color="black" borderRadius="full">
            <Text>NEW</Text>
          </Center>
          <Text fontSize="sm">MOOV token address:</Text>
        </HStack>
        <Text
          fontSize="sm"
          as={CLink}
          href="https://etherscan.io/token/0x4116f14b6d462b32a1c10f98049e4b1765e34fa9"
          target="_blank"
        >
          0x4116f14b6d462B32a1C10F98049E4B1765e34FA9
        </Text>
      </HStack>
      <Stack
        direction={{ base: address ? 'column' : 'row', md: 'row' }}
        w="100%"
        p="6"
        align="center"
        justify={{ base: address ? 'center' : 'space-between', md: 'space-between' }}
        spacing="4"
      >
        <Image w="24" src={MoovLogo} alt="Moov Token" />
        {address ? (
          <ConnectButton />
        ) : (
          <Button
            borderRadius="full"
            bg="brand.500"
            _hover={{ bg: 'brand.400' }}
            color="white"
            onClick={openConnectModal}
          >
            Connect Wallet
          </Button>
        )}
        f
      </Stack>
    </VStack>
  )
}
