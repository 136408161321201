import { ChakraProvider } from '@chakra-ui/react'
import { RainbowKitProvider, darkTheme } from '@rainbow-me/rainbowkit'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { WagmiProvider } from 'wagmi'
import theme from './utils/theme'
import config from './utils/wagmi-config'

import './utils/axios-config'

import { Outlet } from 'react-router-dom'
import MainLayout from './layouts/MainLayout'

const queryClient = new QueryClient()

export default function Root() {
  return (
    <ChakraProvider resetCSS={true} theme={theme}>
      <WagmiProvider config={config}>
        <QueryClientProvider client={queryClient}>
          <RainbowKitProvider
            appInfo={{
              appName: 'MOOV',
              learnMoreUrl: 'https://moovtoken.com',
              disclaimer: () => <div>Disclaimer</div>,
            }}
            theme={darkTheme({
              accentColor: '#6680ff',
              accentColorForeground: '#ffffff',
              borderRadius: 'large',
              fontStack: 'system',
            })}
          >
            <MainLayout>
              <Outlet />
            </MainLayout>
          </RainbowKitProvider>
        </QueryClientProvider>
      </WagmiProvider>
    </ChakraProvider>
  )
}
