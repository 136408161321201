import { isAfter } from 'date-fns'
import { useCallback, useEffect, useState } from 'react'

export default function useCountdown(endDate: Date, onEnd?: () => void) {
  const [timeLeft, setTimeLeft] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
    days: 0,
  })

  const leadingZero = (num: number) => (num < 10 ? '0' + num : num)

  const getTimeUntil = useCallback(
    (date: Date) => {
      const time = date.getTime() - new Date().getTime()

      if (time <= 0) {
        setTimeLeft({ minutes: 0, seconds: 0, hours: 0, days: 0 })
        onEnd && onEnd() // Call the onEnd function when the countdown gets to 0
      } else {
        const seconds = Math.floor((time / 1000) % 60)
        const minutes = Math.floor((time / 1000 / 60) % 60)
        const hours = Math.floor((time / 1000 / 60 / 60) % 24)
        const days = Math.floor(time / 1000 / 60 / 60 / 24)
        setTimeLeft({ seconds, minutes, hours, days })
      }
    },
    [onEnd]
  )

  useEffect(() => {
    if (isAfter(new Date(), endDate)) {
      return
    }

    getTimeUntil(endDate)

    const id = setInterval(() => {
      getTimeUntil(endDate)
    }, 1000)

    return () => clearInterval(id)
  }, [endDate, onEnd, getTimeUntil]) // Make sure to include onEnd in your dependencies array

  return { timeLeft, leadingZero }
}
