import { useConnectModal } from '@rainbow-me/rainbowkit'
import useCountdown from '../../hooks/useCountdown'
import { Button, Text, VStack } from '@chakra-ui/react'
import { useAccount } from 'wagmi'

export default function StakingActions({
  stake,
  unstake,
  isStaking,
  isStakingLocked,
  needsApproval,
  approve,
  endDate,
  isLoading,
  refetch,
  stakingTier,
}: {
  stake: () => void
  unstake: () => void
  isStaking: boolean
  isStakingLocked: boolean
  needsApproval: boolean
  approve: () => void
  endDate: Date | null
  isLoading: boolean
  refetch: () => void
  stakingTier: number
}) {
  const { openConnectModal } = useConnectModal()

  const { address } = useAccount()

  const {
    timeLeft: { days, hours, minutes, seconds },
  } = useCountdown(endDate || new Date(), refetch)

  if (!address) {
    return (
      <Button
        w="100%"
        borderRadius="full"
        bg="brand.500"
        _hover={{ bg: 'brand.400' }}
        color="white"
        onClick={openConnectModal}
      >
        Connect Wallet
      </Button>
    )
  }

  if (isStakingLocked && endDate) {
    return (
      <Button fontSize="sm" w="100%" variant="secondary" disabled>
        <VStack spacing="0.5">
          <Text>Unstake available in</Text>
          {(days !== 0 || hours !== 0) && (
            <Text fontSize="xs">
              {days} days, {hours} hours
            </Text>
          )}
          {days === 0 && hours === 0 && (
            <Text fontSize="xs">
              {minutes} minutes, {seconds} seconds
            </Text>
          )}
        </VStack>
      </Button>
    )
  }

  if (isStaking) {
    return (
      <Button variant="secondary" w="100%" onClick={unstake} isLoading={isLoading}>
        Unstake
      </Button>
    )
  }

  if (stakingTier === 3) {
    return (
      <Button w="100%" isDisabled>
        Staking is not live
      </Button>
    )
  }

  if (needsApproval) {
    return (
      <Button w="100%" onClick={approve} isLoading={isLoading}>
        Approve
      </Button>
    )
  }

  return (
    <Button w="100%" onClick={stake} isLoading={isLoading}>
      Stake
    </Button>
  )
}
