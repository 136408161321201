import axios from 'axios'
import { addresses, stakingAbi, tokenAbi } from '../../utils/contracts'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useAccount, useReadContract, useSignMessage, useWaitForTransactionReceipt, useWriteContract } from 'wagmi'
import BigNumber from 'bignumber.js'
import { formatDuration, intervalToDuration, isAfter, isBefore } from 'date-fns'
import { Text, VStack, useToast, Link, Stack, Input, Button, HStack } from '@chakra-ui/react'
import StakingActions from './StakingActions'
import { maxUint256 } from 'viem'
import Card from '../../components/Card'

export default function StakingTier2({
  isActivated,
  refetchActivation,
}: {
  isActivated: boolean
  refetchActivation: () => void
}) {
  const { address } = useAccount()
  const [email, setEmail] = useState('')
  const [isEditing, setIsEditing] = useState(false)

  const toast = useToast()

  const { data: tier2Info = [0, 0, 0], refetch } = useReadContract({
    address: addresses.staking,
    abi: stakingAbi,
    functionName: 'userInfoTier2',
    args: [address],
  })

  const { data: STAKE_TIER2_AMOUNT = 22000000000000000000000 } = useReadContract({
    address: addresses.staking,
    abi: stakingAbi,
    functionName: 'STAKE_TIER2_AMOUNT',
  })

  const { data: STAKE_TIER2_DURATION = 7776000 } = useReadContract({
    address: addresses.staking,
    abi: stakingAbi,
    functionName: 'STAKE_TIER2_DURATION',
  })

  const { signMessageAsync } = useSignMessage()

  const [tier2Start, tier2End, tier2Amount] = tier2Info as [bigint, bigint, bigint]
  const isStakingT2 = useMemo(() => tier2Amount > 0, [tier2Amount])

  const isStakingLockedT2 = useMemo(
    () =>
      isBefore(new Date(), new Date(Number(tier2End) * 1000)) &&
      isAfter(new Date(), new Date(Number(tier2Start) * 1000)),
    [tier2Start, tier2End]
  )

  const { data: allowance = 0 } = useReadContract({
    address: addresses.token,
    abi: tokenAbi,
    functionName: 'allowance',
    args: [address, addresses.staking],
  })

  // useWriteContract for approve
  const { writeContractAsync, isPending, data: hash } = useWriteContract()

  const {
    isLoading: isConfirming,
    isSuccess: isConfirmed,
    isError,
  } = useWaitForTransactionReceipt({
    hash,
  })

  useEffect(() => {
    if (isConfirmed || isError) {
      refetch()
    }
  }, [isConfirmed, refetch, isError])

  useEffect(() => {
    const interval = setInterval(() => {
      refetch()
    }, 60000)

    return () => clearInterval(interval)
  }, [refetch])

  const handleApprove = useCallback(async () => {
    try {
      await writeContractAsync({
        address: addresses.token,
        abi: tokenAbi,
        functionName: 'approve',
        args: [addresses.staking, maxUint256],
      })
    } catch (err) {
      console.error(err)
      toast({
        title: 'Could not approve',
        description: err.message?.split('.')?.[0] ?? err.message,
        status: 'error',
      })
    }
  }, [writeContractAsync, toast])

  const handleStake = useCallback(async () => {
    try {
      await writeContractAsync({
        address: addresses.staking,
        abi: stakingAbi,
        functionName: 'stakeTier2',
        args: [],
      })
    } catch (err) {
      toast({
        title: 'Could not stake',
        description: err.message?.split('.')?.[0] ?? err.message,
        status: 'error',
      })
      console.error(err)
    }
  }, [toast, writeContractAsync])

  const handleUnstake = useCallback(async () => {
    try {
      await writeContractAsync({
        address: addresses.staking,
        abi: stakingAbi,
        functionName: 'unstakeTier2',
        args: [],
      })
    } catch (err) {
      console.error(err)
      toast({
        title: 'Could not unstake',
        description: err.message?.split('.')?.[0] ?? err.message,
        status: 'error',
      })
    }
  }, [toast, writeContractAsync])

  const handleSignMessage = useCallback(async () => {
    try {
      //const message = `Staking tier 3 dotmoovs: ${email}\nTimestamp: ${new Date().toISOString()}`
      const message = email
      const signature = await signMessageAsync({
        message,
      })

      await axios.post('/staking/activate', {
        address,
        message,
        signature,
      })

      refetchActivation()
      setIsEditing(false)

      console.log('data sent to backend', {
        address,
        message,
        signature,
      })
    } catch (err) {
      toast({
        title: 'Could not sign message',
        description: err.message?.split('.')?.[0] ?? err.message,
        status: 'error',
      })
    }
  }, [email, signMessageAsync, toast, address, refetchActivation])

  const durationTimestamp = new Date(Number(STAKE_TIER2_DURATION) * 1000)
  const duration = intervalToDuration({ start: 0, end: durationTimestamp })
  const formattedDuration = formatDuration(duration)

  return (
    <VStack w="100%" spacing="4">
      <Stack
        w="100%"
        direction={{ base: 'column', md: 'row' }}
        overflow="hidden"
        borderRadius="24px"
        border="1px solid"
        borderColor="whiteAlpha.500"
        spacing="0"
      >
        <VStack align="start" w="100%" bg="white" color="black" p="5">
          <VStack align="start" spacing="0.5" pb="6">
            <Text fontSize="xl" fontWeight="semibold" color="brand.500">
              Dotmoovs Active User
            </Text>
          </VStack>
          <VStack spacing="0.5" align="start">
            <Text fontSize="md" fontWeight="semibold">
              Benefits
            </Text>
            <Text fontSize="sm">Free subscription for three months</Text>
          </VStack>
          {isStakingT2 && (
            <VStack w="100%" h="100%" justify="end" align="start" pt="2">
              {isActivated && !isEditing ? (
                <HStack w="100%" justify="space-between">
                  <Text fontSize="sm">Activated</Text>
                  <Text
                    fontSize="sm"
                    fontWeight="semibold"
                    cursor="pointer"
                    color="brand.500"
                    onClick={() => setIsEditing(true)}
                  >
                    Change Account
                  </Text>
                </HStack>
              ) : (
                <>
                  <Text fontSize="sm">Enter email of your dotmoovs account</Text>
                  <Input bg="blackAlpha.200" size="sm" value={email} onChange={(e) => setEmail(e.target.value)} />
                  <Button w="100%" onClick={handleSignMessage}>
                    Activate Benefits
                  </Button>
                </>
              )}
            </VStack>
          )}
        </VStack>
        <VStack bg="black" w="100%" p="5">
          <Card>
            <VStack align="start" w="100%" spacing="0">
              <Text fontWeight="light" fontSize="sm" color="whiteAlpha.800">
                Stake Amount
              </Text>
              <Text fontWeight="semibold">
                {new BigNumber((STAKE_TIER2_AMOUNT as bigint).toString()).div(10 ** 18).toString()} MOOV
              </Text>
            </VStack>
          </Card>
          <Card>
            <VStack align="start" w="100%" spacing="0">
              <Text fontWeight="light" fontSize="sm" color="whiteAlpha.800">
                Stake Duration
              </Text>
              <Text fontWeight="semibold">{formattedDuration}</Text>
            </VStack>
          </Card>
          <Card>
            <VStack align="start" w="100%" spacing="0">
              <Text fontWeight="light" fontSize="sm" color="whiteAlpha.800">
                Your Stake
              </Text>
              <Text fontWeight="semibold">
                {new BigNumber((tier2Amount as bigint).toString()).div(10 ** 18).toString()} MOOV
              </Text>
            </VStack>
          </Card>
          <StakingActions
            stake={handleStake}
            unstake={handleUnstake}
            isStaking={isStakingT2}
            isStakingLocked={isStakingLockedT2}
            needsApproval={new BigNumber((allowance as bigint).toString()).isLessThan(
              new BigNumber((STAKE_TIER2_AMOUNT as bigint).toString())
            )}
            approve={handleApprove}
            isLoading={isPending || isConfirming}
            refetch={refetch}
            endDate={tier2End ? new Date(Number(tier2End) * 1000) : null}
            stakingTier={2}
          />
        </VStack>
      </Stack>
      {hash && (
        <Text
          p="3"
          bg="blackAlpha.700"
          borderRadius="lg"
          textAlign="center"
          fontSize="sm"
          as={Link}
          textDecoration="underline"
          href={`https://etherscan.io/tx/${hash}`}
          target="_blank"
        >
          View transaction on Etherscan: {hash?.slice(0, 12)}...{hash?.slice(-12)}
        </Text>
      )}
    </VStack>
  )
}
