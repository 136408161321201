import { BoxProps, HStack } from '@chakra-ui/react'

export default function Card({ children, ...props }: { children: React.ReactNode } & BoxProps) {
  return (
    <HStack
      borderRadius="16px"
      bg="black"
      fontWeight="semibold"
      p="6"
      w="100%"
      justify="space-between"
      border="1px solid"
      borderColor="whiteAlpha.500"
      {...props}
    >
      {children}
    </HStack>
  )
}
