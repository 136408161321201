import { useConnectModal } from '@rainbow-me/rainbowkit'
import { useAccount, useReadContract, useWaitForTransactionReceipt, useWriteContract } from 'wagmi'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useToast } from '@chakra-ui/toast'
import { formatEther, fromHex, http } from 'viem'
import {
  Button,
  VStack,
  Text,
  Container,
  Box,
  Image,
  HStack,
  Center,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Input,
  Link,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useClipboard,
  Spinner,
} from '@chakra-ui/react'
import tree from '../data/merkle.json'
import MoovIcon from '../assets/moovicon.svg'
import useCountdown from '../hooks/useCountdown'
import { createWalletClient, custom } from 'viem'
import { mainnet } from 'viem/chains'
import { watchAsset } from 'viem/actions'
import { claimAbi } from '../utils/contracts'
import Card from '../components/Card'

type MerkleTree = {
  merkleRoot: string
  tokenTotal: string
  claims: Record<string, { index: number; amount: string; proof: string[] }>
}

export const claimAddress = '0xA04cD5153d2f81aBD32d218A9644a2b5c264f60B'

const tokenAddress = '0x4116f14b6d462b32a1c10f98049e4b1765e34fa9'

const App = () => {
  const { address } = useAccount()
  const { openConnectModal } = useConnectModal()
  const toast = useToast()

  const client = createWalletClient({
    chain: mainnet,
    transport: window?.ethereum ? custom(window?.ethereum) : http(),
  })

  const handleAddToken = useCallback(async () => {
    try {
      if (!client) {
        throw new Error('No wallet connected')
      }
      await watchAsset(client, {
        type: 'ERC20',
        options: {
          address: tokenAddress,
          decimals: 18,
          symbol: 'MOOV',
          image: 'https://claim.moovtoken.com/logo512.png',
        },
      })

      toast({
        title: 'MOOV token added',
        description: 'MOOV token',
        status: 'success',
      })
    } catch (err) {
      toast({
        title: 'Could not add MOOV token',
        description: err.message?.split('.')?.[0] ?? err.message,
        status: 'error',
      })
    }
  }, [toast, client])

  const {
    timeLeft: { days, hours, minutes, seconds },
  } = useCountdown(new Date(1714154400 * 1000))

  const [customAddress, setCustomAddress] = useState('')
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [sendToCustomAddress, setSendToCustomAddress] = useState<boolean>(false)

  const addressToClaim = useMemo(
    () => (sendToCustomAddress ? customAddress : address),
    [sendToCustomAddress, customAddress, address]
  )

  const { writeContractAsync, isPending, data: hash } = useWriteContract()

  const { onCopy, hasCopied } = useClipboard(tokenAddress)

  const merkleTree = tree as MerkleTree

  const addresses = useMemo(() => Object.keys(merkleTree.claims), [merkleTree.claims])

  const holder = useMemo(() => addresses.find((wallet) => wallet === addressToClaim), [addressToClaim, addresses])

  const isHolder = useMemo(() => holder !== undefined, [holder])

  const holderInfo = useMemo(
    () => (isHolder ? addressToClaim && merkleTree.claims?.[addressToClaim] : undefined),
    [addressToClaim, isHolder, merkleTree.claims]
  )

  const { data: isClaimed = false, refetch } = useReadContract({
    address: claimAddress,
    abi: claimAbi,
    functionName: 'isClaimed',
    args: [holderInfo ? holderInfo?.index : -1],
  })

  const {
    isLoading: isConfirming,
    isSuccess: isConfirmed,
    isError,
  } = useWaitForTransactionReceipt({
    hash,
  })

  useEffect(() => {
    if (isConfirmed) {
      refetch()
    }
  }, [isConfirmed, refetch, isError])

  const amountToClaim = holderInfo ? formatEther(fromHex(holderInfo.amount as `0x${string}`, 'bigint')) : '0'

  const handleClaim = useCallback(async () => {
    try {
      setIsLoading(true)
      if (!holderInfo) {
        throw new Error('Not eligible to claim MOOV')
      }

      await writeContractAsync({
        abi: claimAbi,
        address: claimAddress,
        functionName: 'claim',
        args: [holderInfo.index, addressToClaim, holderInfo.amount, holderInfo.proof],
      })
    } catch (err) {
      console.error(err)
      toast({
        title: 'Could not claim MOOV',
        description: err.message?.split('.')?.[0] ?? err.message,
        status: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }, [toast, writeContractAsync, holderInfo, addressToClaim])

  return (
    <Container pt="0" pb="20" maxW="600px" px="20px">
      <Tabs>
        <TabList>
          <Tab>Claim</Tab>
          <Tab>Timeline</Tab>
          <Tab>FAQ</Tab>
          <Tab>CEX</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <VStack align="start" w="100%" spacing="2">
              <Card>
                <VStack align="start" spacing="0">
                  <Text fontWeight="light" fontSize="sm" color="whiteAlpha.800">
                    Network
                  </Text>
                  <Text>Ethereum Mainnet</Text>
                </VStack>
              </Card>
              <Card>
                <VStack align="start" spacing="0" w="100%">
                  <HStack w="100%" justify="space-between">
                    <Text fontWeight="light" fontSize="sm" color="whiteAlpha.800">
                      Address
                    </Text>
                    {sendToCustomAddress ? (
                      <Button
                        size="sm"
                        bg="transparent"
                        color="white"
                        textDecoration="underline"
                        _hover={{}}
                        _active={{}}
                        onClick={() => setSendToCustomAddress(false)}
                      >
                        Claim your address
                      </Button>
                    ) : (
                      <Button
                        size="sm"
                        bg="transparent"
                        color="white"
                        textDecoration="underline"
                        _hover={{}}
                        _active={{}}
                        onClick={() => setSendToCustomAddress(true)}
                      >
                        Claim another address
                      </Button>
                    )}
                  </HStack>
                  {sendToCustomAddress ? (
                    <Input
                      bg="blackAlpha.200"
                      outline="0"
                      border="0"
                      borderBottom="1px solid"
                      borderColor="whiteAlpha.600"
                      borderRadius="0"
                      px="0"
                      _focusVisible={{ borderColor: 'white' }}
                      _placeholder={{ color: 'whiteAlpha.800' }}
                      size="sm"
                      placeholder="Enter the address to claim"
                      value={customAddress}
                      onChange={(e) => setCustomAddress(e.target.value)}
                    />
                  ) : (
                    <Box maxW={{ base: '280px', md: '100%' }}>
                      <Text isTruncated>{address ? address : '-'}</Text>
                    </Box>
                  )}
                </VStack>
              </Card>
              <Card>
                <VStack align="start" spacing="0">
                  <Text fontWeight="light" fontSize="sm" color="whiteAlpha.800">
                    {isClaimed ? 'You have claimed' : 'You can claim'}
                  </Text>
                  <Box maxW={{ base: '180px', md: '100%' }}>
                    <Text fontSize={{ base: 'lg', md: 'xl' }} isTruncated>
                      {address ? amountToClaim : '-'}
                    </Text>
                  </Box>
                </VStack>

                <Menu>
                  <MenuButton role="group">
                    <Center
                      w="130px"
                      bg="white"
                      px="3"
                      py="2"
                      borderRadius="full"
                      border="1px solid"
                      borderColor="blackAlpha.300"
                      transition="all 0.2s ease-in-out"
                      _groupHover={{ bg: 'gray.100' }}
                    >
                      <HStack justify="center" spacing="1" w="100%" px="16px">
                        <Image w="7" h="7" src={MoovIcon} alt="Moov Icon" />
                        <Text fontWeight="semibold" fontSize="lg" color="black">
                          MOOV
                        </Text>
                        <VStack pl="1.5" spacing="1.5px">
                          <Box w="2.5px" h="2.5px" bg="blackAlpha.700" borderRadius="full"></Box>
                          <Box w="2.5px" h="2.5px" bg="blackAlpha.700" borderRadius="full"></Box>
                          <Box w="2.5px" h="2.5px" bg="blackAlpha.700" borderRadius="full"></Box>
                        </VStack>
                      </HStack>
                    </Center>
                  </MenuButton>
                  <MenuList>
                    <MenuItem onClick={handleAddToken}>Add to Wallet</MenuItem>
                    <MenuItem onClick={onCopy}>{hasCopied ? 'Copied!' : 'Copy Token Address'}</MenuItem>
                    <MenuItem
                      as={Link}
                      href="https://etherscan.io/token/0x4116f14b6d462b32a1c10f98049e4b1765e34fa9"
                      target="_blank"
                    >
                      View on Etherscan
                    </MenuItem>
                    <MenuItem as={Link} href="https://coinmarketcap.com/currencies/dotmoovs/" target="_blank">
                      View on CoinMarketCap
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Card>

              {(days !== 0 || hours !== 0 || minutes !== 0 || seconds !== 0) && (
                <Button
                  borderRadius="full"
                  w="100%"
                  size="lg"
                  bg="brand.500"
                  _hover={{ bg: 'brand.400' }}
                  color="white"
                  py="8"
                >
                  <VStack w="100%" spacing="2">
                    <Text fontSize="sm">Claim available in</Text>
                    <Text fontSize="sm">
                      {days} days, {hours} hours, {minutes} minutes, {seconds} seconds
                    </Text>
                  </VStack>
                </Button>
              )}
              {days === 0 && hours === 0 && minutes === 0 && seconds === 0 && (
                <>
                  {!address ? (
                    <Button
                      borderRadius="full"
                      w="100%"
                      size="lg"
                      bg="brand.500"
                      _hover={{ bg: 'brand.400' }}
                      color="white"
                      onClick={openConnectModal}
                    >
                      Connect Wallet
                    </Button>
                  ) : (
                    <Button
                      borderRadius="full"
                      w="100%"
                      size="lg"
                      bg="brand.500"
                      _hover={{ bg: 'brand.400' }}
                      color="white"
                      onClick={handleClaim}
                      isDisabled={(Boolean(isClaimed) || !isHolder) ?? false}
                    >
                      <HStack spacing="2">
                        {(isLoading || isPending || isConfirming) && !isClaimed && <Spinner boxSize="3.5" />}
                        <Text fontSize="md">
                          {isPending && 'Accept in your wallet...'}
                          {isConfirming && 'Waiting for confirmation...'}
                          {!isLoading && !isPending && !isConfirming && (
                            <>
                              {isClaimed ? 'MOOV already claimed' : !isHolder ? 'Wallet not MOOV holder' : 'Claim MOOV'}
                            </>
                          )}
                        </Text>
                      </HStack>
                    </Button>
                  )}
                </>
              )}
              {hash && (
                <Text
                  p="3"
                  bg="blackAlpha.700"
                  borderRadius="lg"
                  textAlign="center"
                  fontSize="sm"
                  as={Link}
                  textDecoration="underline"
                  href={`https://etherscan.io/tx/${hash}`}
                  target="_blank"
                >
                  View transaction on Etherscan: {hash?.slice(0, 12)}...{hash?.slice(-12)}
                </Text>
              )}
            </VStack>
          </TabPanel>
          <TabPanel>
            <VStack
              align="start"
              spacing="6"
              p="6"
              bg="black"
              border="1px solid"
              borderColor="whiteAlpha.500"
              borderRadius="16px"
            >
              <VStack align="start" spacing="0.5">
                <Text fontWeight="light" fontSize="sm" color="whiteAlpha.800">
                  April 24, 2024 from 15:00 GMT to 19:00 GMT
                </Text>
                <Text>Removal of liquidity from exchanges</Text>
                <Text fontSize="sm" color="whiteAlpha.800" fontWeight="light">
                  All LP providers should remove liquidity before this date. Our team will remove its liquidity on all
                  exchanges in that timeframe to prepare for the snapshot of all addresses.{' '}
                </Text>
              </VStack>

              <VStack align="start" spacing="0.5">
                <Text fontWeight="light" fontSize="sm" color="whiteAlpha.800">
                  April 25, 2024 at 12:00 GMT
                </Text>
                <Text>Snapshot of token holders balances</Text>
                <Text fontSize="sm" color="whiteAlpha.800" fontWeight="light">
                  At this date we will collect all the balances of all token holders and save it. Future transactions
                  will not be taken into account. Token holders should not do any transfers of MOOV after this!
                </Text>
              </VStack>

              <VStack align="start" spacing="0.5">
                <Text fontWeight="light" fontSize="sm" color="whiteAlpha.800">
                  April 26, 2024 at 18:00 GMT
                </Text>
                <Text>New MOOV tokens launched on Ethereum Mainnet</Text>
                <Text fontSize="sm" color="whiteAlpha.800" fontWeight="light">
                  There will be a website available for token holders to claim their MOOV tokens. The old tokens will no
                  longer be valid.
                </Text>
              </VStack>
              <VStack align="start" spacing="0.5">
                <Text fontWeight="light" fontSize="sm" color="whiteAlpha.800">
                  April 26, 2025 at 18:00 GMT
                </Text>
                <Text>Migration period ends</Text>
                <Text fontSize="sm" color="whiteAlpha.800" fontWeight="light">
                  The migration period begins on April 26, 2024 at 18:00 GMT and lasts until April 26, 2025 at 18:00
                  GMT.
                </Text>
              </VStack>
            </VStack>
          </TabPanel>
          <TabPanel>
            <VStack
              align="start"
              spacing="6"
              p="6"
              bg="black"
              border="1px solid"
              borderColor="whiteAlpha.500"
              borderRadius="16px"
            >
              <VStack align="start" spacing="0.5">
                <Text>What happens if tokens aren’t migrated?</Text>
                <Text fontSize="sm" color="whiteAlpha.800" fontWeight="light">
                  After the migration window closes, all unclaimed tokens will be securely burned. We encourage all
                  holders to take advantage of the migration period to avoid any loss of assets.
                </Text>
              </VStack>

              <VStack align="start" spacing="0.5">
                <Text>Is this only for tokens in Binance Smart Chain?</Text>
                <Text fontSize="sm" color="whiteAlpha.800" fontWeight="light">
                  No, the migration applies to MOOV in all chains. Token holders will have to claim the new MOOV tokens
                  in Ethereum Mainnet.
                </Text>
              </VStack>

              <VStack align="start" spacing="0.5">
                <Text>How to migrate your MOOV tokens</Text>
                <Text fontSize="sm" color="whiteAlpha.800" fontWeight="light">
                  Detailed instructions for migrating your MOOV tokens will be provided closer to the migration date.
                  Rest assured, our team is committed to facilitating a seamless transition for every member of our
                  community.
                </Text>
              </VStack>

              <VStack align="start" spacing="0.5">
                <Text>What happens to liquidity?</Text>
                <Text fontSize="sm" color="whiteAlpha.800" fontWeight="light">
                  As part of the migration process, liquidity will be transitioned from the original contract pool to a
                  new one. This ensures continuity and liquidity provision within the MOOV ecosystem.{' '}
                </Text>
              </VStack>

              <VStack align="start" spacing="0.5">
                <Text>Are we only going to be in ETH?</Text>
                <Text fontSize="sm" color="whiteAlpha.800" fontWeight="light">
                  We have plans in motion to expand to new ecosystems, it’s part of our strategy, and everything will be
                  announced as soon as possible.
                </Text>
              </VStack>
            </VStack>
          </TabPanel>
          <TabPanel>
            <VStack
              align="start"
              spacing="6"
              p="6"
              bg="black"
              border="1px solid"
              borderColor="whiteAlpha.500"
              borderRadius="16px"
            >
              <VStack align="start" spacing="0.5">
                <Text fontSize="sm" pb="2">
                  Centralized Exchanges will be supporting the migration process. We will be working with them to ensure
                  a smooth transition for all token holders.
                </Text>

                <Text
                  as={Link}
                  href="https://www.htx.com/support/54968079724871"
                  target="_blank"
                  fontSize="sm"
                  textDecoration="underline"
                >
                  HTX to support MOOV(Dotmoovs) contract upgrade
                </Text>
                <Text
                  as={Link}
                  href="https://www.coinex.com/en/announcements/detail/25920037148308"
                  target="_blank"
                  fontSize="sm"
                  textDecoration="underline"
                >
                  CoinEx Will Support MOOV Smart Contract Swap
                </Text>
                <Text
                  as={Link}
                  href="https://www.mexc.com/support/articles/17827791515474"
                  target="_blank"
                  fontSize="sm"
                  textDecoration="underline"
                >
                  MEXC Will Support the Dotmoovs (MOOV) Contract Swap
                </Text>
                <Text
                  as={Link}
                  href="https://www.gate.io/article/36152"
                  target="_blank"
                  fontSize="sm"
                  textDecoration="underline"
                >
                  Gate.io Supports dotmoovs (MOOV) Token Migration
                </Text>
              </VStack>
            </VStack>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Container>
  )
}

export default App
