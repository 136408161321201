import Header from '../components/Header'
import { Box, Center, HStack, Text } from '@chakra-ui/react'
import { ReactNode, useMemo } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Background from '../assets/bg.jpg'

export default function MainLayout({ children }: { children: ReactNode }) {
  const location = useLocation()

  const isClaimPage = useMemo(() => location.pathname === '/', [location.pathname])
  const isStakingPage = useMemo(() => location.pathname.includes('staking'), [location.pathname])

  return (
    <Box w="100%" h="100%" minH="100vh" overflowY="auto" bgImage={Background} bgSize="cover">
      <Header />
      <Center w="100%">
        <HStack borderRadius="full" p="1" mb="4">
          <Link to="/">
            <Center borderBottom="1px solid" borderBottomColor={isClaimPage ? 'white' : 'transparent'} py="2" px="4">
              <Text fontSize="md" fontWeight="semibold" color={isClaimPage ? 'white' : 'whiteAlpha.600'}>
                Claim
              </Text>
            </Center>
          </Link>
          <Link to="/staking">
            <Center borderBottom="1px solid" borderBottomColor={isStakingPage ? 'white' : 'transparent'} py="2" px="4">
              <Text fontSize="md" fontWeight="semibold" color={isStakingPage ? 'white' : 'whiteAlpha.600'}>
                Staking
              </Text>
            </Center>
          </Link>
        </HStack>
      </Center>
      {children}
    </Box>
  )
}
